import React from "react";
import logo from "../../images/gig_logo_white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import Copyright from "./Copyright";

function Footer() {
  return (
    <>
      <footer className="bg-darkNavyBlue relative w-auto bottom-0 z-20 h-[340px] mx-auto max-w-screen">
        <div className="max-w-screen-xl mx-auto w-full items-center justify-center">
          <div className="md:pl-[60px] lg:pr-[120px]">
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4 lg:gap-8 px-4 py-6 lg:py-8 lg:grid-cols-6">
              <div className="hidden lg:flex lg:col-span-2 mt-2">
                <NavLink to="/" className="flex flex-col items-center">
                  <img src={logo} className="h-20 me-3" alt="Logo" />

                  <span className="font-roboto text-[15px] font-normal leading-relaxed text-white p-2 ">
                    GiG is a self-service ticketing platform for live
                    experiences that allows anyone to create, share, find and
                    attend events that fuel their passions and enrich their
                    lives.
                  </span>
                </NavLink>
              </div>
              <div>
                <h2 className="sm:mb-6 font-medium md:font-semibold text-white hover:text-customOrange dark:text-white font-roboto text-[14px] md:text-lg leading-relaxed">
                  Products
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium ">
                  <li className="md:mb-4">
                  <NavLink
                      to="/events"
                      className="hover:underline font-roboto leading-relaxed text-[10px] md:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      Explore Events
                    </NavLink>
                  </li>
                  {/* <li className="md:mb-4">
                    <a
                      href="#2"
                      className="hover:underline font-roboto  leading-relaxed text-[10px] md:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      Community
                    </a>
                  </li> */}
                  <li className="md:mb-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://client.gig.co.ke/client-register"
                      className="hover:underline font-roboto  leading-relaxed text-[10px] md:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      Organizer?
                    </a>
                  </li>
                  {/* <li className="md:mb-4">
                    <NavLink
                      to="/pricing"
                      className="hover:underline font-roboto leading-relaxed text-[10px] md:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      Pricing
                    </NavLink>
                  </li> */}
                </ul>
              </div>
              <div>
                <h2 className="sm:mb-6 font-medium md:font-semibold text-white hover:text-customOrange dark:text-white font-roboto text-[14px] md:text-lg leading-relaxed">
                  GiG
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium ">
                  <li className="md:mb-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://site.gig.co.ke/services"
                      className="hover:underline font-roboto leading-relaxed text-[10px] md:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      About Us
                    </a>
                  </li>
                  <li className="md:mb-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://site.gig.co.ke/contact"
                      className="hover:underline font-roboto  leading-relaxed text-[10px] md:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      Contact Us
                    </a>
                  </li>
                  {/* <li className="md:mb-4">
                    <a
                      href="#3"
                      className="hover:underline font-roboto  leading-relaxed text-[10px] md:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      Blog
                    </a>
                  </li> */}
                  {/* <li className="md:mb-4">
                    <a
                      href="#3"
                      className="hover:underline font-roboto  leading-relaxed text-[10px] md:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      FAQs
                    </a>
                  </li> */}
                </ul>
              </div>
              <div>
                <h2 className="sm:mb-6 font-medium md:font-semibold text-white hover:text-customOrange dark:text-white font-roboto text-[14px] md:text-lg leading-relaxed">
                  Legal
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium ">
                  <li className="md:mb-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://site.gig.co.ke/privacy"
                      className="hover:underline font-roboto leading-relaxed text-[10px] md:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="md:mb-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://site.gig.co.ke/terms"
                      className="hover:underline font-roboto  leading-relaxed text-[10px] md:text-sm text-whiteFooter hover:text-customOrange"
                    >
                      Terms &amp; Conditions
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="sm:mb-6 font-medium md:font-semibold text-white hover:text-customOrange dark:text-white font-roboto text-[14px] md:text-lg leading-relaxed">
                  Social Media
                </h2>
                <div className="grid grid-cols-2 sm:flex sm:flex-row md:flex mt-2 justify-start md:mt-0 gap-2 sm:gap-0">
                  <a
                    href="https://www.facebook.com/guiderig/"
                    className="text-facebook hover:text-gray-900 dark:hover:text-white sm:ms-5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} size="xl" />
                    <span className="sr-only">Facebook page</span>
                  </a>
                  <a
                    href="https://twitter.com/gigeventske"
                    className="text-white hover:text-gray-900 dark:hover:text-white sm:ms-5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faXTwitter} size="xl" />
                    <span className="sr-only">Twitter page</span>
                  </a>
                  <a
                    href="https://www.instagram.com/gigeventske/"
                    className="text-instagram hover:text-gray-900 dark:hover:text-white sm:ms-5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} size="xl" />
                    <span className="sr-only">Instagram Page</span>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/guiderig/?viewAsMember=true"
                    className="text-linkedIn hover:text-gray-900 dark:hover:text-white sm:ms-5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} size="xl" />
                    <span className="sr-only">Discord community</span>
                  </a>
                </div>
              </div>
            </div>
            <hr className="border border-red mx-auto w-full h-[1px] z-0" />
            <div className="flex items-center justify-center mt-[15px] sm:mt-[-2px] md:mt-[15px] mb-[20px]">
              <span>
                <Copyright />
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
